import { mapGetters } from "vuex";
import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_FILM_EPISODE,
  GET_FILM_EPISODES,
  STORE_FILM_EPISODE,
  UPDATE_FILM_EPISODE
} from "@/store/modules/film.episode.module";
import { GET_FILM_EPISODE_URL_LIST } from "../store/modules/film.episode.module";

export default {
  computed: {
    ...mapGetters(['filmEpisode', 'filmEpisodes', 'filmEpisodeMeta', 'filmEpisodeError', 'filmEpisodePage'])
  },
  methods: {
    getFilmEpisode(id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_FILM_EPISODE, id),
      actions
      );
    },
    getFilmEpisodes(film_id, page = 1, title = '', actions = {
      begin: ()=>{ this.showBusy('filmBusy'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.hideBusy('filmBusy'); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(
        this.$store.dispatch(GET_FILM_EPISODES, {film_id, page, title}),
        actions
      );
    },
    storeFilmEpisode(film_id, payload = {}, actions = {
      begin: ()=>{ this.$emit('storing'); },
      after: (response, state)=>{ this.$emit('stored', {response, state}); },
    }){
      return mixinPromise(
        this.$store.dispatch(STORE_FILM_EPISODE, { film_id, payload }),
        actions
      );
    },
    updateFilmEpisode(formData = {}, actions = {
      begin: ()=>{ this.$emit('updating'); },
      after: (response, state)=>{ this.$emit('updated', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(UPDATE_FILM_EPISODE, formData),
      actions
      );
    },
    getFilmEpisodeUrlList(film_id, actions = {
      begin: ()=>{ this.$emit('loading'); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); },
    }){
      return mixinPromise(
      this.$store.dispatch(GET_FILM_EPISODE_URL_LIST, film_id),
      actions
      );
    },
  }
}
