<template>
  <div class="episode-form-container">
    <b-row>
      <b-col sm="6" md="2" v-if="!singleMode">
        <b-form-group id="input-group-is_show" label="ئوچۇقمۇ ؟" label-for="is_show">
          <b-form-radio-group
                  id="is_show"
                  v-model="formData.is_show"
                  :options="yesOrNot"
                  buttons />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2" v-if="!formData.need_vip && ! hideOther">
        <b-form-group id="input-group-need_payment" label="ھەقلىقمۇ؟" label-for="need_payment">
          <b-form-checkbox id="need_payment" @change="onChangeNeedPayment" switch v-model="formData.need_payment"/>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="2" v-if="!formData.need_payment && ! hideOther">
        <b-form-group id="input-group-need_vip" label="VIP تەلەپ قىلامدۇ؟" label-for="need_vip">
          <b-form-checkbox id="need_vip" switch @change="onChangeNeedVip" v-model="formData.need_vip"/>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="formData.need_payment && ! hideOther">
      <b-col sm="6" md="2">
        <b-form-group id="input-group-price" :state="validateState('price')" aria-describedby="price-live-feedback" label="باھاسى" label-for="price" description="نۆۋەتتىكى قىسىمنىڭ باھاسىنى كىرگۈزۈڭ">
          <b-form-input style="width: 100%; direction: ltr" step="0.01" :state="validateState('price')" aria-describedby="price-live-feedback" id="price" v-model="formData.price" type="number" placeholder="فىلىم باھاسىنى كىرگۈزۈڭ" />
        </b-form-group>
        <b-form-invalid-feedback id="price-live-feedback">
          نۆۋەتتىكى قىسىمنىڭ باھاسىنى كىرگۈزۈڭ
        </b-form-invalid-feedback>
      </b-col>
      <b-col sm="6" md="2" v-if="! hideOther">
        <b-form-group id="input-group-vip_should_paid" label="VIP ئەزامۇ پۇل تۆلەمدۇ؟" label-for="need_payment">
          <b-form-radio-group
            id="vip_should_paid"
            v-model="formData.vip_should_paid"
            :options="[{text: 'شۇنداق', value: true},{text: 'ياق', value: false}]"
            buttons />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!singleMode">
      <b-col sm="6" md="3">
        <b-form-group id="input-group-title" label="قىسىم نامى" label-for="title" description="نۆۋەتتىكى قىسىمنىڭ نامىنى كىرگۈزۈڭ">
          <b-form-input id="title" v-model="formData.title" type="text" placeholder="بىرىنجى قىسىم، بىرىنجى بۆلۈم دىگەندەك كىرگۈزۈڭ" />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-sort" label="قىسىم تەرتىبى" label-for="sort" description="نۆۋەتتىكى قىسىمنىڭ تەرتىبىنى كىرگۈزۈڭ">
          <b-form-input style="width: 100%; direction: ltr" id="sort" v-model="formData.sort" type="number" placeholder="قىسىم تەرتىبىنى كىرگۈزۈڭ، قۇرۇق قويسىڭىزمۇ بولىدۇ" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="6" md="12">
        <b-form-group id="input-group-media_source" :state="validateState('media_source')" aria-describedby="media_source-live-feedback" label=" فىلىم مەنبەسى " label-for="media_source">
          <b-form-radio-group
            :state="validateState('media_source')" aria-describedby="media_source-live-feedback"
            id="media_source"
            v-model="formData.media_source"
            :options="filmMediaSource"
            buttons
          ></b-form-radio-group>
          <b-form-invalid-feedback id="media_source-live-feedback">
            نۆۋەتتىكى قىسىمنىڭ فىلىم مەنبەسىنى تاللاڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="formData.media_source === 'mp4'" sm="6" md="6">
        <b-form-group id="input-group-play_url" :state="validateState('play_url')" aria-describedby="play_url-live-feedback" label="بىۋاستە قويۇش ئادېرسى" label-for="play_url" description="نۆۋەتتىكى قىسىمنىڭ بىۋاستە قويۇلۇش ئادېرسىنى كىرگۈزۈڭ">
          <b-form-textarea v-if="multiCreator" style="direction: ltr" :state="validateState('play_url')" aria-describedby="play_url-live-feedback" id="play_url" v-model="formData.play_url" placeholder="فىلىم ئادېرسىنى كىرگۈزۈڭ" />
          <b-form-input v-if="!multiCreator" style="direction: ltr" :state="validateState('play_url')" aria-describedby="play_url-live-feedback" id="play_url" v-model="formData.play_url" placeholder="فىلىم ئادېرسىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="play_url-live-feedback">
            نۆۋەتتىكى قىسىمنىڭ بىۋاستە قويۇلۇش ئادېرسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="formData.media_source === 'we_chat_news'" sm="6" md="6">
        <b-form-group id="input-group-source_url" label="تېما ئادېرسى" label-for="source_url" >
          <b-input-group >
            <b-input-group-append>
              <b-button @click="parseInfo" :disabled="!isUrl" v-b-tooltip.hover title="ئۇچۇر ئېلىش" variant="success">
                ئۇچۇرغا ئېرىشىش
              </b-button>
            </b-input-group-append>
            <b-form-input :state="validateState('source_url')" aria-describedby="source_url-live-feedback" v-model="formData.source_url" style="direction: ltr" placeholder="سالون تېمىسى قىسقا ئادېرسىنى كىرگۈزۈڭ" />
            <b-form-invalid-feedback style="direction: ltr; text-align: left;" id="url-live-feedback">
              سالون يازمىسى قىسقا ئادېرسىنى توغرا تولدۇرۇپ قايتا سىناڭ
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col v-if="formData.media_source === 'tencent'" sm="6" md="6">
        <b-form-group id="input-group-source_url" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" label="تېڭشۈن ۋىدىئولىرى ئادېرسى" label-for="source_url" description="نۆۋەتتىكى قىسىمنىڭ تېڭشۈن ۋىدىئو ئادېرسىنى كىرگۈزۈڭ">
          <b-form-textarea v-if="multiCreator" style="direction: ltr" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" id="source_url" v-model="formData.source_url" placeholder="تېڭشۈن ۋىدىئو ئادېرسىنى كىرگۈزۈڭ" />
          <b-form-input v-if="!multiCreator" style="direction: ltr" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" id="source_url" v-model="formData.source_url" placeholder="تېڭشۈن ۋىدىئو ئادېرسىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="source_url-live-feedback">
            نۆۋەتتىكى قىسىمنىڭ تېڭشۈن ۋىدىئو ئادېرسىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="formData.media_source === 'wxv'" sm="6" md="6">
        <b-form-group id="input-group-source_url" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" label="wxv سالون id" label-for="source_url" description="نۆۋەتتىكى قىسىمنىڭ wxv سالون id نى كىرگۈزۈڭ">
          <b-form-textarea v-if="multiCreator" style="direction: ltr" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" id="source_url" v-model="formData.source_url" placeholder="نۆۋەتتىكى قىسىمنىڭ wxv سالون id نى كىرگۈزۈڭ" />
          <b-form-input v-if="!multiCreator" style="direction: ltr" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" id="source_url" v-model="formData.source_url" placeholder="نۆۋەتتىكى قىسىمنىڭ wxv سالون id نى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="source_url-live-feedback">
            نۆۋەتتىكى قىسىمنىڭ wxv سالون id سىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="formData.media_source === 'vid'" sm="6" md="6">
        <b-form-group id="input-group-source_url" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" label="vid تېڭشۈن id" label-for="source_url" description="نۆۋەتتىكى قىسىمنىڭ vid تېڭشۈن id نى كىرگۈزۈڭ">
          <b-form-textarea v-if="multiCreator" style="direction: ltr" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" id="source_url" v-model="formData.source_url" placeholder="نۆۋەتتىكى قىسىمنىڭ vid تېڭشۈن id نى كىرگۈزۈڭ" />
          <b-form-input v-if="!multiCreator" style="direction: ltr" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" id="source_url" v-model="formData.source_url" placeholder="نۆۋەتتىكى قىسىمنىڭ vid تېڭشۈن id نى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="source_url-live-feedback">
            نۆۋەتتىكى قىسىمنىڭ vid تېڭشۈن id سىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col v-if="['tapqu_cntv', 'tapqu'].includes(formData.media_source)" md="6" sm="6">
        <b-form-group id="input-group-source_url" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" :label="`${formData.media_source === 'tapqu' ? 'تاپقۇ مەنبە VID' : 'CNTV VID'}`" label-for="source_url" >
          <b-form-textarea v-if="multiCreator" style="direction: ltr" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" id="source_url" v-model="formData.source_url" placeholder="VID قىممىتىنى كىرگۈزۈڭ" />
          <b-form-input v-if="!multiCreator" style="direction: ltr" :state="validateState('source_url')" aria-describedby="source_url-live-feedback" id="source_url" v-model="formData.source_url" placeholder="VID قىممىتىنى كىرگۈزۈڭ" />
          <b-form-invalid-feedback id="source_url-live-feedback">
            نۆۋەتتىكى قىسىمنىڭ vid سىنى كىرگۈزۈڭ
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!formData.need_payment && !formData.need_vip && formData.task_fragment && ! hideOther">
      <b-col sm="6" md="3">
        <b-form-group id="input-group-share-time" label="بەھىرلەش ۋاقتى" label-for="share-time">
          <b-form-timepicker hide-header show-seconds :hour12="false" no-close-button menu-class="direction-ltr" style="direction: ltr" dropleft locale="de" v-model="formData.task_fragment.share_time" placeholder="توپقا بەھىرلەش ۋاقتىنى تاللاڭ"></b-form-timepicker>
        </b-form-group>
      </b-col>
      <b-col sm="6" md="3">
        <b-form-group id="input-group-advert-time" label="ئېلان ۋاقتى" label-for="share-time">
          <b-form-timepicker hide-header show-seconds :hour12="false" no-close-button menu-class="direction-ltr" style="direction: ltr" dropleft locale="de" v-model="formData.task_fragment.advert_time" placeholder=" ئېلان كۆرۈش ۋاقتىنى تاللاڭ"></b-form-timepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <template class="free_fragment" v-if="formData.need_payment || formData.need_vip && ! hideOther" >
      <b-form-group id="input-group-free-fragment" label="تېتىم بۆلىكى" label-for="addFreeFragment" description="قوشۇش كونۇپكىسىنى بېسىپ، فىلىمدىكى ئەڭ نادىر بىر قانچە بۆلەكنىڭ باشلىنىش ۋە ئاياقلىشىش مىنۇت سانىنى تاللاپ بېرىڭ، بۇ ئارقىلىق ئەزالارنىڭ پۇل تۆلەپ كۆرۈش ئىستىكىنى ئاشۇرۇش مۇمكىن.">
        <b-row>
          <b-col class="mb-3 mt-2" sm="6" md="2" v-for="(fragment, i) in formData.free_fragment" :key="`free_fragment${i}`">
            <time-fragment-item :index="i" :start_time="fragment.start_time" :end_time="fragment.end_time" @edit="onEditFreeFragmentItem" @delete="onDeleteFreeFragment" />
          </b-col>
          <b-col class="mb-3 mt-2" sm="6" md="2">
            <b-btn-group>
              <b-button id="addFreeFragment" variant="primary" @click="onClickAddFragment">قوشۇش</b-button>
              <b-button id="clearFreeFragment" v-if="formData.free_fragment.length && formData.free_fragment.length > 2" variant="danger" @click="onClickClearFragment">تازلاش</b-button>
            </b-btn-group>
          </b-col>
        </b-row>
      </b-form-group>
    </template>
    <b-form-group v-if="! hideOther" id="input-group-free-fragment" label=" ئاپتۇماتىك ئۆتكۈزۈش بۆلىكى" label-for="addSkipFragment" description="قوشۇش كونۇپكىسىنى بېسىپ، فىلىمدىكى ئېلان، ۋە باشقا ئۆتكۈزىۋەتمەكچى بولغان بۆلەكلەرنىڭ باشلىنىش ۋە ئاياقلىشىش مىنۇتلىرىنى تاللاپ بېرىڭ. ئەلۋەتتە قۇرۇق قويسىڭىزمۇ بولىدۇ.">
      <b-row>
        <b-col class="mb-3 mt-2" sm="6" md="2" v-for="(fragment, i) in formData.skip_fragment" :key="`skip_fragment${i}`">
          <time-fragment-item :index="i" :start_time="fragment.start_time" :end_time="fragment.end_time" @edit="onEditSkipFragmentItem" @delete="onDeleteSkipFragment" />
        </b-col>
        <b-col class="mb-3 mt-2" sm="6" md="2">
          <b-btn-group>
            <b-button id="addSkipFragment" variant="primary" @click="onClickAddFragment('skip_fragment')">قوشۇش</b-button>
            <b-button id="clearSkipFragment" v-if="formData.skip_fragment.length && formData.skip_fragment.length > 2" variant="danger" @click="onClickClearSkipFragment">تازلاش</b-button>
          </b-btn-group>
        </b-col>
      </b-row>
    </b-form-group>
    <time-fragment-modal v-model="freeFragmentModalState" title="تېتىم بۆلىكى قوشۇڭ" :start_time="fragmentStartTime" :end_time="fragmentEndTime" :index="fragmentIndex" @send="onSendFreeFragment" />
    <time-fragment-modal v-model="skipFragmentModalState" title="ئاپتۇماتىك ئۆتكۈزۈش بۆلىكى قوشۇڭ" :start_time="fragmentStartTime" :end_time="fragmentEndTime" :index="fragmentIndex" @send="onSendSkipFragment" />
    <parse-we-chat-news-info :state="formData.media_source === 'we_chat_news' && parserModalState" @close="handleParserClose" @success="handleParserSuccess" v-model="formData.source_url" />
  </div>
</template>

<script>
  import validateStateMixin from "@m@/common/validateState.mixin";
  import { validationMixin } from "vuelidate";
  import { required } from "vuelidate/lib/validators";
  import sweetAlertMixin from "@m@/common/sweetAlert.mixin";
  import episodeMixin from "@m@/film.episode.mixin";
  import {debugConsole, unique} from "@c@/helpers/helpers";
  import timeFragmentModal from "@v@/components/modals/timeFragmentModal";
  import TimeFragmentItem from "@v@/components/items/TimeFragmentItem";
  import ParseWeChatNewsInfo from "@v@/components/modals/ParseWeChatNewsInfo";
  import { mapGetters } from "vuex";
  export default {
    name: "EpisodeForm",
    components: { timeFragmentModal, TimeFragmentItem, ParseWeChatNewsInfo },
    mixins: [ episodeMixin, validationMixin, validateStateMixin, sweetAlertMixin ],
    props: {
      action: {
        type: String,
        default: ()=> 'default', // create, edit, default
      },
      modelId: {
        type: [String, Number],
      },
      parentModelId: {
        type: [String, Number],
      },
      modelData: {
        type: [Object, null],
        default: ()=> null
      },
      // 当前如果是在新增电影时选择当前电影为单集的话这个选项的来由就是这个
      singleMode: {
        type: Boolean
      },
      hideOther: {
        type: Boolean,
        default: ()=> false
      }
    },
    validations(){
      let validates = {
        media_source: { required },
      };
      if ( ! this.singleMode ){
        // validates['title'] = { required };
      }
      if ( this.formData.need_payment ){
        validates['price'] = { required };
      }
      if ( this.formData.media_source === 'mp4' ){
        validates['play_url'] = { required };
      }else if( this.formData.media_source !== 'mp4' ){
        validates['source_url'] = { required };
      }
      return {
        formData: validates
      }
    },
    computed: {
      ...mapGetters(["filmMediaSource"]),
      isUrl(){
        let { source_url = '' } = this.formData;
        let pattern = /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i;
        return source_url.match(pattern);
      },
      multiCreator(){
        return this.action === 'create';
      },
    },
    watch: {
      'formData.media_source': {
        handler(v){
          if ( v === 'we_chat_news' && this.isUrl){
            this.parseInfo();
          }
        }
      },
      'formData.source_url': {
        handler(){
          if (this.formData.media_source === 'we_chat_news' && this.isUrl){
            this.parseInfo();
          }
        }
      },
      modelData: {
        immediate: true,
        handler(val){
          if ( val ){
            this.formData = val;
            if ( !val.task_fragment ){
              this.formData.task_fragment = {
                share_time: '00:00:00',
                advert_time: '00:00:00'
              };
            }
          }
        }
      }
    },
    created() {
      if (this.action === 'edit'){
        this.getFilmEpisode(this.modelId)
          .then(({data})=>{
            this.formData = data;
            if ( !this.formData.task_fragment ){
              this.formData.task_fragment = {
                share_time: '00:00:00',
                advert_time: '00:00:00'
              };
            }
          })
      }
    },
    mounted() {
      if ( this.singleMode ){
        this.formData.is_show = true;
      }
    },
    data(){
      return {
        yesOrNot: [
          { text: 'شۇنداق', value: true },
          { text: 'ياق', value: false },
        ],
        formData: {
          free_fragment: [],
          skip_fragment: [],
          task_fragment: {
            share_time: '00:00:00',
            advert_time: '00:00:00'
          },
          title: '',
          has_vip: false,
          is_show: true,
          need_payment: false,
          vip_should_paid: false,
          price: null,
          media_source: 'mp4',
          source_url: '',
          play_url: null,
          sort: null,
        },
        freeFragmentModalState: false,
        skipFragmentModalState: false,
        fragmentStartTime: null,
        fragmentEndTime: null,
        fragmentIndex: null,
        parserModalState: false
      }
    },
    methods: {
      onChangeNeedPayment(e){
        if ( ! e ){
          this.formData.vip_should_paid = false;
        }
      },
      onChangeNeedVip(e){
        if ( e ){
          this.formData.vip_should_paid = false;
          this.formData.price = null;
        }
      },
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          return this.warning({message: 'تولدۇرۇشقا تېگىشلىك ئۇچۇرلارنى تۇلۇق تولدۇرۇڭ'});
        }
        if (this.action === 'create'){
          return this.storeFilmEpisode(this.parentModelId,this.formData);
        }else if ( this.action === 'edit' ){
          return this.updateFilmEpisode(this.formData);
        }
      },
      toggleTimeFragment(modalType = 'freeFragmentModalState'){
        this[modalType] = ! this[modalType];
      },
      onClickAddFragment(fragment = 'free_fragment'){
        this.toggleTimeFragment(this.generateFragmentModalType(fragment));
      },

      onClickClearFragment(){
        this.handleClearFragment();
      },
      onClickClearSkipFragment(){
        this.handleClearFragment('skip_fragment');
      },
      handleClearFragment(fragmentType = 'free_fragment'){
        if ( this.formData.hasOwnProperty(fragmentType) ){
          this.formData[fragmentType] = [];
        }
      },

      generateFragmentModalType(fragmentType = 'free_fragment'){
        let modalType = 'freeFragmentModalState';
        if ( fragmentType === 'free_fragment' ){
          modalType = 'freeFragmentModalState';
        }else if ( fragmentType === 'skip_fragment' ){
          modalType = 'skipFragmentModalState';
        }
        return modalType;
      },

      setFragmentPayload(payload = null, payloadType = 'start_time'){
        switch (payloadType) {
          case "start_time":
            this.fragmentStartTime = payload;
            break;
          case "end_time":
            this.fragmentEndTime = payload;
            break;
          case "index":
            this.fragmentIndex = payload;
            break;
        }
      },
      setFragment(item, index = null, fragmentKey = 'free_fragment'){
        let fragment = this.formData[fragmentKey];
        if ( index === null ){
          fragment.push(item);
        }else {
          fragment[index] = item;
        }
        this.formData[fragmentKey] = unique(fragment);
      },

      onSendFreeFragment(e){
        this.handleSendFragment(e);
      },
      onSendSkipFragment(e){
        this.handleSendFragment(e, 'skip_fragment');
      },
      handleSendFragment(e, fragmentType = 'free_fragment'){
        let { start_time, end_time, index } = e;
        this.setFragment({start_time, end_time}, index, fragmentType);
        this.$nextTick(function () {
          this.toggleTimeFragment(this.generateFragmentModalType(fragmentType));
          this.setFragmentPayload(null, 'start_time');
          this.setFragmentPayload(null, 'end_time');
          this.setFragmentPayload(null, 'index');
        });
        debugConsole({ prefix: 'SendFragment', message: JSON.stringify({start_time, end_time, index, fragmentType}) });
      },

      onEditFreeFragmentItem(index){
        this.handleEditFragment(index);
      },
      onEditSkipFragmentItem(index){
        this.handleEditFragment(index, 'skip_fragment');
      },
      handleEditFragment(index, fragmentType = 'free_fragment'){
        let fragment = this.formData[fragmentType];
        let currentFragment = fragment[index];
        this.setFragmentPayload(currentFragment['start_time'], 'start_time');
        this.setFragmentPayload(currentFragment['end_time'], 'end_time');
        this.setFragmentPayload(index, 'index');
        this.$nextTick(function () {
          this.toggleTimeFragment(this.generateFragmentModalType(fragmentType));
        });
      },

      onDeleteFreeFragment(index){
        this.handleDeleteFragmentItem(index);
      },
      onDeleteSkipFragment(index){
        this.handleDeleteFragmentItem(index, 'skip_fragment');
      },
      handleDeleteFragmentItem(index, fragmentType = 'free_fragment'){
        let { formData } = this;
        formData[fragmentType].splice(index, 1);
        this.formData = formData;
      },

      handleParserClose(){
        this.parserModalState = false;
      },
      showParserModal(){
        this.parserModalState = true;
      },
      handleParserSuccess(response){
        let { videos } = response.data.data;
        this.handleParserClose();
        if ( this.singleMode && (videos.wxv.length && videos.wxv.length > 1) || (videos.vid.length && videos.vid.length > 1) ){
          this.warning({message: `مەزكۇر تېمىدا كۆپ دانە فىلىم ئۇچۇرى بايقالدى، كۆپ قىسىملىق فىلىم ھالىتىدە قوشقايسىز.`});
          return;
        }else if ( this.action === 'edit' && (videos.wxv.length && videos.wxv.length > 1) || (videos.vid.length && videos.vid.length > 1) ){
          this.warning({message: `مەزكۇر تېمىدا كۆپ دانە فىلىم ئۇچۇرى بايقالدى، كۆپ قىسىملىق فىلىم ھالىتىدە ياكى يېڭى قىسىم قوشۇش ھالىتىدە قوشقايسىز.`});
          return;
        }
        if ( videos.wxv.length ){
          this.formData.media_source = 'wxv';
          this.formData.source_url = videos.wxv.join("\r");
        }else if( videos.vid.length ){
          this.formData.media_source = 'vid';
          this.formData.source_url = videos.vid.join("\r");
        }
      },
      onChangeMediaSource(e){
        if ( e === 'we_chat_news' ){
          this.parseInfo();
        }
      },
      parseInfo(){
        return this.isUrl && this.showParserModal();
      }
    }
  }
</script>
